div#details {
  border-left: 1px solid #e0e0e0;
  background-color: #fff;
}

div#detailsHeader {
  background-color: #f9f9f9;
  padding: 16px 20px;
  position: relative;
  border-bottom: 1px solid #e0e0e0;
}

div#detailsHeader span {
  font-weight: bold;
  display: block;
  font-size: 14px;
  line-height: 22px;
}

div#detailsFooter {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

img#sendingItems {
  width: 40px;
  height: 40px;
}

div#detailsItem {
  padding: 16px 20px;
  height: calc(100vh - 205px);
  overflow-y: scroll; /* has to be scroll, not auto */
  /*-webkit-overflow-scrolling: touch;*/
}

button.btn-send-items {
  background-color: #24cfa2;
  border-radius: 0;
  border: 0px;
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  padding: 25px 20px;
}

div#details ul {
  padding: 0px;
  margin: 0px;
}

div#details ul.details li {
  padding: 5px 0px;
  margin: 0px;
  color: #131517;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}

div#details ul.details.totals li {
  color: #939597;
}

div#details li {
  list-style-type: none;
}

hr {
  margin: 20px 0;
}


@media screen and (max-width: 450px) {
  div#details {
    border-left: none;
  }
  div#detailsHeader {
    border-bottom: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;
  }
  .mobileNav div#detailsItem {
    height: inherit;
  }
  .mobileNav div#detailsItem {
    padding-bottom: 100px;
  }
}
