div#loginKeypad div.keypad-label {
  color: #a3a5a7;
}
div#loginKeypad table.keypad td button.number {
  background-color: #343539;
  color: #fff;
}
div#loginKeypad table.keypad td {
  border-color: #101315;
}

div#loginKeypad {
  background-color: #343539;
}

div#loginKeypad input[type="password"] {
  -webkit-text-security: disc;
  font-family: caption;
}

div#loginKeypad input.display-number {
  background-color: #343539 !important;
  color: #fff;
}

div#loginKeypad table.keypad td:nth-child(3n + 0) {
  border-right: none;
}

div#loginKeypad table.keypad td:nth-child(3n + 1) {
  border-left: none;
}

div#loginKeypad table.keypad td button.number.extra {
  background-color: #282c2f;
  color: #a3a5a7;
}

div#loginKeypad table.keypad td button,
div#loginKeypad table.keypad td button.number.extra {
  -webkit-tap-highlight-color: rgba(28,32,34,1);
}
div#loginKeypad table.keypad td button:active,
div#loginKeypad table.keypad td button.number.extra:active {
  background-color: #1c2022;
}
div#loginKeypad button#btnSend {
  -webkit-tap-highlight-color: rgba(25,157,122,1);
  border-color: #199d7a;
}
div#loginKeypad button#btnSend:active {
  background-color: #199d7a;
  border-color: #199d7a;
}

div#loginKeypad input.display-number.display-number-error {
  color: #e41e2d;
}
