html,
body {
  min-height: 100vh !important;
  height: 100vh;
  margin: 0;
  padding: 0;
  position: relative;
  background-color: #101315 !important;
}

body {
  font-size: 0.875rem;
}

div#root {
  height: 100%;
}

.fullHeight {
  height: 100%;
}

img {
  width: 100%;
}

:focus,
btn:focus {
  outline: none;
  border-color: inherit;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.middleWrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  background-color: transparent;
  z-index: 4;
}
.middleWrap .middleContent {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 450px) {
  .middleWrap .middleContent {
    vertical-align: top;
    top: 54px;
  }
}
