div.rwt-container-loading {
  padding-left: 0;
  padding-right: 0;
  height: 100%;
  position: absolute;
  top: 0;
  padding-top: 54px;
}

img#loadingRwtImage {
  width: 40px;
  height: 40px;
  margin-bottom: 20px;
}

i#loadingErrorRwtIcon {
  font-size: 52px;
  margin-bottom: 20px;
  color: #939597;
}

div#loadingRwtMessage {
  color: #ffffff;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 10px;
}

div#errorLoadingRwtMessage {
  color: #ffffff;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 10px;
}
