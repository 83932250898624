div.btn-rooam-add {
  text-align: left;
  background-color: #343a41;
  color: #fff;
  font-size: 20px;
  line-height: 20px;
  padding: 20px;
  border-radius: 5px;
  border: 0px;
  height: inherit;
  position: relative;
  z-index: 1;
  -webkit-tap-highlight-color: rgba(36,42,48,1);
}
div.btn-rooam-add:active {
  background-color: #242a30;
}

button.btn-rooam-delete {
  background-color: #343a41;
  color: #fff;
  border-left: 1px solid #636567;
  font-size: 18px;
  width: 64px;
  height: 100%;
  border-radius: 0 5px 5px 0;
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-tap-highlight-color: rgba(36,42,48,1);
}
button.btn-rooam-delete:active {
  background-color: #242a30;
}
