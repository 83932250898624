div#menuItemList {
  height: calc(100vh - 54px);
  overflow-y: scroll;
  /*-webkit-overflow-scrolling: touch;*/
}

div.item-list {
  background-color: #f0f0f0;
}

@media screen and (max-width: 450px) {
  .mobileNav div#menuItemList {
    height: inherit;
  }
}

.search-wrap {
  display: block;
  position: relative;
  margin: 10px 10px 2px 10px;
}
span.form-clear {
  display: block;
  width: 40px;
  height: 100%;
  position: absolute;
  top: 5px;
  right: 0;
  font-size: 14px;
  line-height: 14px;
  margin: -6px 0 0 0;
  padding: 0;
  cursor: pointer;
  color: #888888;
}
span.form-clear i {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -7px;
  padding: 0;
}
