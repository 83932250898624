.sidebar {
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

.sidebar-sticky {
  height: calc(100vh - 54px);
  overflow-x: hidden;
  overflow-y: scroll;
  /*-webkit-overflow-scrolling: touch;*/
}

.sidebar .nav-link {
  font-weight: 600;
  color: #333;
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  padding: 18px 19px;
}
.sidebar .nav-link.active {
  background-color: #353739;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

nav.categories {
  background-color: #232527;
}

@media screen and (max-width: 450px) {
  .rwt-container-tab.mobileNav, div.rwt-container-tab.mobileNav .fullHeight {
    height: inherit;
  }
  .mobileNav .sidebar-sticky, .mobileNav div#menuItemList {
    height: inherit;
  }
}
