/*
 * Navbar
 */

.navbar {
  height: 54px;
}

.navbar span.navbar-user-name {
  position: absolute;
  top: 0;
  left: 54px;
  padding: 20px 11px;
  background-color: #050709;
  font-size: 12px;
  line-height: 14px;
  color: #fff;
  border: 0px;
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.navbar-brand {
  margin: 0;
  position: relative;
  left: 17px;
  width: 36px;
  height: 36px;
  padding-top: 0px!important;
  padding-bottom: 0px!important;
}

.navbar-brand .logo {
  background-color: #030507;
  width: 36px;
  height: 36px;
  -webkit-border-radius: 10px;
  border-radius: 6px;
  border: 1px solid #434547;
  position: relative;
  top: 0;
}
.navbar-brand .logo .icon {
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  position: absolute;
  left: 50%;
  top: 50%;
}
.navbar-brand .logo .icon img {
  vertical-align: top;
}

.navbar-dark {
  background-color: #050709;
}

.navbar .form-control {
  padding: 0.75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}

.nav .nav-item button {
  padding: 20px;
  background-color: #050709;
  font-size: 12px;
  line-height: 14px;
  color: #fff;
  border: 0px;
  border-left: 1px solid #333537;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  -webkit-tap-highlight-color: rgba(32,35,37,1);
}
.nav .nav-item button:active {
  background-color: #202325;
}
