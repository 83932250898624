/* Keypad style */
div.rwt-container-keypad {
  padding-left: 0;
  padding-right: 0;
  height: 100%;
  position: absolute;
  top: 0;
}

div.keypad-container {
  margin: auto;
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  max-width: 480px;
  border: 0px;
}

div.keypad-container div.keypad-label {
  letter-spacing: 0.1rem;
  font-size: 0.6rem;
  text-align: center;
  color: #a3a5a7;
  text-transform: uppercase;
  padding-top: 22px;
}

div.keypad-container div.keypad-label.keypad-label-error {
}

div.keypad-container table.keypad {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  display: table;
}
table.keypad tbody {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  display: table;
}
table.keypad tbody tr {
  width: 100%;
  display: table;
}
table.keypad td {
  width: 33%;
  text-align: center;
  padding: 0px;
  border: 1px solid #d0d0d0;
  border-bottom: none;
  padding: 0px;
  margin: 0px;
  vertical-align: top;
}

table.keypad tr:last-child td {
  border: 0px;
  padding: 0px;
}

table.keypad td button.number {
  height: 72px;
  width: 100%;
  color: #737577;
  font-size: 30px;
  line-height: 30px;
  font-weight: 600;
  border-radius: 0px;
  background-color: #fff;
}
table.keypad td button.number.extra {
  background-color: #f4f4f4;
}
table.keypad td button.number.extra i {
  font-size: 25px;
  line-height: 35px;
}
table.keypad td button,
table.keypad td button.number.extra {
  -webkit-tap-highlight-color: rgba(233,233,233,1);
}
table.keypad td button:active,
table.keypad td button.number.extra:active {
  background-color: #e9e9e9;
}

input.display-number {
  border: 0px;
  letter-spacing: 0.5rem;
  padding: 12px 0 20px;
  font-size: 100%;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  width: 100%;
  color: #434547;
  font-weight: 500;
  background-color: #fff;
}
.keypad-container .form-control:disabled,
.form-control[readonly] {
  background-color: #fff !important;
}

input.display-number.display-number-error {
  color: #e51e2d;
}

input.display-number::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #d3d5d7;
  opacity: 1; /* Firefox */
}

input.display-number:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #d3d5d7;
}

input.display-number::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #d3d4d6;
}

button#btnSend {
  background-color: #24cfa2;
  border-radius: 0 0 10px 10px;
  border: 1px solid #24cfa2;
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  padding: 24px 10px;
  font-weight: 600;
}

@media screen and (max-width: 450px) {
  div.keypad-container {
    border-radius: 0px;
  }
  table.keypad td button.number {
    height: 70px;
  }
  button#btn-send {
    border-radius: 0px;
    font-size: 20px;
    line-height: 30px;
    padding: 22px 10px;
  }
  table.keypad td:nth-child(3n + 0) {
    border-right: none;
  }
  table.keypad td:nth-child(3n + 1) {
    border-left: none;
  }
}
